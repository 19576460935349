import React from "react";
import { graphql } from "gatsby";
import List from "./List";

function ListNews({ data, pageContext }) {
  return (
    <List nodes={data.allNodeArticle} pageContext={pageContext} hasStickies={true} />
  );
}

export default ListNews;

export const query = graphql`
  query newsQuery {
    allMarkdownRemark(filter: { frontmatter: { slug: { eq: "news" } } }) {
      nodes {
        frontmatter {
          title
        }
      }
    }
    allNodeArticle(sort: { fields: field_date, order: DESC }) {
      edges {
        node {
          drupal_id
          field_date(formatString: "MMMM Do, YYYY")
          title
          sticky
          body {
            processed
            summary
          }
          fields {
            slug
          }
          relationships {
            field_tags {
              drupal_id
              name
            }
          }
        }
      }
    }
  }
`;
